var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("workTable", {
    ref: "workTable",
    attrs: { filterIndex: 0, "table-title-json": _vm.tableTitleJson },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }